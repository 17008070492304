<template>
  <v-main :style="[ mobileCheck ? {paddingTop: '35px'} : {} ]">
    <router-view />
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',
    computed:{
      mobileCheck(){
        return window.innerWidth < window.innerHeight && window.innerWidth < 480 || window.innerWidth > window.innerHeight && window.innerHeight < 480
      }
    }
  }
</script>
